import "./App.css";
import { useEffect, useState } from "react";
import { initCardanoDAppConnectorBridge } from "./dapp.connector";

function App() {
  const [api, setApi] = useState();
  const [rAdr, setRAdr] = useState();

  useEffect(() => {
    initCardanoDAppConnectorBridge((walletApi) => {
      alert(walletApi.name);

      walletApi.enable().then((a) => setApi(a));
    });
  }, []);

  useEffect(() => {
    if (!api) return;

    api.getRewardAddresses().then((addrs) => setRAdr(addrs));
  }, [api]);

  function connect() {
    if (!window.cardano?.flint) {
      alert("Not available");
      return;
    }

    window.cardano.flint
      .enable()
      .then((a) => setApi(a))
      .catch(console.error);
  }

  return (
    <div className="App">
      <header className="App-header">
        <button onClick={() => connect()}>Flint</button>

        {api && <p>Connected!!!</p>}
        {rAdr && <pre>{JSON.stringify(rAdr, null, 2)}</pre>}
      </header>
    </div>
  );
}

export default App;
